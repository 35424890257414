body {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-family: Ubuntu, sans-serif;
}

p {
  line-height: 1.3em;
  font-size: 18px;
}

@media (max-width: 767px) {
  p {
    font-size: 16px;
  }
}

::selection {
  background: #197a8c;
  color: #fff;
}
